import React from "react"
import Log from "./../../../templates/log.js"

import { Datasheet, Extern, FirstP } from "./../../../components/helpers.js"

export default ({ data, pageContext }) => {

    const content = <>

        <FirstP>Even though it is many months ago that I wrote the first log in this project, I am a lot closer to my first design than I was then. 
            As presented in the previous post, there are three main methods to control a brushless motor, each one having slightly different needs for the hardware design. 
            Here I will present which components are needed for a BLDC controller, to be able to use all three methods. Read on for part selection and the reasoning behind my choices.
            
             </FirstP>
         
         <p>Let me start of by saying that I am of course not the first one or only one to develop a BLDC controller. Next to many commercial solutions there are two open-source projects that
             stand out in particular which are known as <Extern href="https://vesc-project.com/">VESC</Extern> and <Extern href="https://odriverobotics.com">ODrive</Extern>. ODrive targets itself towards servo and robotics applications, while VESC is very popular for things like DIY electric skateboard
             builds. Much of the inspiration for my own design comes from these two projects, but there are a few things that I wanted to do different as well. My wishlist is:
         </p>

        <ul>
            <li>Keep the form factor small enough to be used on my quadcopter, while keeping the board one-sided for easier assembly. 
            </li>
            <li>I understand it will be a compromise, but ideally I would like the controller to be suitable for everything from a small drone all the way to an electric bicycle.</li>
            <li>Have voltage and current measurements for all three phases of the motor. This is something that the standard versions of ODrive and VESC do not have.
            </li>
            <li>
                Have a dedicated debugging port to connect a SWD debugger for flashing and development.
            </li>
            <li>
                Compatible with sensorless, hall sensors or encoders
            </li>
            <li>                 
                A rating of around 48V and 30A continuous            
            </li>            
        </ul>

        <p>            
            Next, I will go over all components that are needed and how I selected them. This post might be a bit dry, but if you are interested to do something similar I hope it 
            will be useful.
        </p>

        <h3>Microcontroller</h3>

        <Datasheet href="https://www.st.com/resource/en/datasheet/stm32g473ce.pdf" title="ST STM32G473CE" />

        <p>
            As a microcontroller I decided to go for a relatively powerful STM32 in a small form factor. I selected the STM32G473CE with an FPU, 170Mhz and 512 kB of ROM. Within the same form factor, versions with different clock speeds, and available
            memory are typically pin for pin compatible. That makes it easy to downgrade after real-world testing to see how much of that I actually need.
        </p>   

        <h3>MOSFETs</h3>

        <Datasheet href="https://www.infineon.com/dgdl/Infineon-BSC014N06NS-DataSheet-v02_04-EN.pdf?fileId=db3a3043382e837301386ab95a521dcd" title="Infineon BSC014N06NS" />

        <p>Selecting the MOSFET was the hardest part, since there are so many options. This one is rated up to 60V and there are three main aspects I looked at:
        <ol>
                <li><b>Form factor</b>: this TDSON-8 Package seems to have a particular good thermal resistance for its size.
            </li>
                <li><b>Conduction losses</b>: When the MOSFET is conducting, the current through its internal resistance will generate heat. This part has a very low resistance of 1.45 mOhm.
            </li>
                <li><b>Switching losses</b>: The switching losses in a MOSFET can be calculated as 0.5*V*I*(tr+tf)*F, in which tr and tf are the rise and falling time of one switching period
                and F is the switching frequency.
            </li>
            </ol>
            By doing some rough calculations this MOSFET should have enough thermal capacity to meet my 30A and 48V targets. in this situation the losses per MOSFET should be roughly 1.5W.
        </p>     
         
        <h3>Gate Driver</h3>

        <Datasheet href="https://www.ti.com/lit/ds/symlink/drv8305.pdf?ts=1600593279839" title="TI DRV8305" />

        <p>Both VESC and ODrive use a TI gate driver from the same series,
            but the DRV8305 is unique in that it is compatible with 3 current shunts instead of 2. This allows for better sensing. </p><p>Another advantage of the DRV8305
            is that you can configure the current to the MOSFETs. Normally you would need to put a resistor between the gate driver and the MOSFET to limit the current and 
            prevent instabilities. Since I was not confident enough in my calculations for this, the DRV8305 is a nice choice because I can test different settings with the same design.
            </p><p>The only downside for this gate driver is that the max voltage is 45V which is slightly below what I wanted. But its a sacrifice I am willing to make since I have no 
                actual short term applications where this will be needed anyway.</p>




        <h3>Power Supply</h3>

        <Datasheet href="https://www.ti.com/lit/ds/symlink/lm2594.pdf?ts=1600594943626" title="TI LM2594HV" />

        <p>I will use a buck converter to generate 3.3V for the microcontroller. Next to that a small 5V supply is needed to power hall sensors or an encoder when available. 
            The DRV8305 actually contains a very low current 5V supply, so we get this one for free.         
            </p>

        <h3>Other components</h3>

        Next to these main components, the board will contain:

        <ul>
            <li><b>Bypass capacitors</b>: 2 times 220uF seemed a good trade-off between footprint and capacity.
            </li>
            <li><b>Motor connector</b>: Hall sensors or popular CUI encoders both use a 5V, GND and three digital pins. So I will include a connector that can be used for both depending on the motor.
            </li>
            <li><b>Debug connector</b>: Standard SWD connector to connect a Segger debugger to make development a lot easier.
            </li>
            <li>
                <b>Other</b>: A few minor things such as a temperature sensor, status led and an additional connector for external control inputs.
            </li>
        </ul>

        <h3>PCB design</h3>

        <p>That's it! I am currently designing the PCB with these components, and hope to be ready within a few weeks from now. At a first glance it seems feasible to package all of this
            in a one-sided PCB of roughly 4x7 cm. Stay tuned, because in the next update I hope to be able to show you the actual circuit board.
        </p>

    </>;

    return (<Log pageContext={pageContext}>{content}</Log>);
}